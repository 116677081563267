import React from 'react';
import * as images from './../../images';
import './header.css';

function Header() {
  return (
    <>
      <header className="header">
        <div className="logo-container">
          <a href='/'>
            <img src={images.Logo} alt="Logo" />
          </a>
        </div>
        <div className="header-buttons">
          <button className="btn" onClick={() => {
            window.open('https://lulasalud.com', '_blank')
          }}>Iniciar sesión</button>
        </div>
      </header>

      <header className="header-mobile">
        <img src={images.LogoMobile} alt="Logo" />
      </header>
    </>
  );
}

export default Header;
