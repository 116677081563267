import React from 'react';
import Carousel, { companyLogos } from './../CompanyLogos';
import './footer.css';

function Footer() {
    return (
        <div className='footer'>
            <h3>Tu equipo estará en buena compañía</h3>
            <span>Miles de empleados en las compañías líderes de Latam nos recomiendan</span>
            <Carousel>
                {companyLogos.map((logo, index) => (
                    <img key={index} src={logo.src} alt={logo.alt} />
                ))}
            </Carousel>
        </div>
    );
}

export default Footer;
