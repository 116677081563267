import React from 'react';
import './info-card.css';

function InfoCard({ imgFile, boldText, text, size = '1', textReversed = false }) {
    return (
        <div className='info-card-container' style={{ flex: size }}>
            <div className='info-card-image-container'>
                <img src={imgFile} alt='img' />
            </div>
            {
                textReversed ?
                    <p className='info-card-text'>{boldText} <span>{text}</span></p> :
                    <p className='info-card-text'><span>{boldText}</span> {text}</p>
            }

        </div>
    );
}

export default InfoCard;
