import React from 'react';
import './hero.css';
import HubspotForm from 'react-hubspot-form'
import * as images from './../images';
import InfoCard from './../components/InfoCard';

const FormFuncionalidad = () => {
  return (
    <div className="hero-contact-section">
      <div className="hero-contact-content">
        <img src={images.functionalBG} alt='img' />
        <h3>EXPERTOS en Seguros Corporativos</h3>
        <h1>El futuro de la salud y seguros para tu empresa está aquí.</h1>
        <h2>Mucho más que un broker, te acompañamos con telemedicina, nutrición psicología yendo a una vida más saludable para tu equipo.</h2>
        <div className='info-container'>
          <InfoCard size='1.7' imgFile={images.gifIcon} boldText={'Sesiones gratis'} text={'de salud mental, nutrición y telemedicina preventiva.'} />
          <InfoCard size='1.7' imgFile={images.starIcon} boldText={'Una sola herramienta con IA'} text={'para gestionar tu seguro y tu salud'} />
          <InfoCard size='1.2' imgFile={images.nomIcon} boldText={'Aplicación'} text={'NOM-035.'} textReversed={true}/>
          <InfoCard size='0.3' imgFile={images.supportIcon} boldText={'Atención'} text={'24/7.'}  textReversed={true}/>
        </div>
      </div>
      <div className='hero-contact-form-container'>
        <div className={'hero-contact-form'}>
          <p><strong>Regístrate</strong> ahora para obtener acceso a nuestras <strong>sesiones gratuitas</strong> y descubre cómo podemos mejorar tu calidad de vida</p>
          <HubspotForm
            portalId='20394660'
            formId={process.env.REACT_APP_FORM_ID}
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div></div>}
          />
        </div>
      </div>
    </div>
  );
}

export default FormFuncionalidad;
