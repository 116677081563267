import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import FormFuncionalidad from './pages/Funcionalidad';
import FormAhorro from './pages/Ahorro';
import Header from './components/Header';
import Footer from './components/Footer';

const RoutesComponent = () => {
  const siteType = process.env.REACT_APP_SITE_KIND;
  return (
    <Routes>
      <Route path="/" element={siteType === 'AHORRO' ? <FormAhorro /> : <FormFuncionalidad />} />
    </Routes>
  );
}

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <RoutesComponent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
