import React, { useState, useEffect, useRef } from 'react';
import './company_logos.css';
import * as images from './../../images';

export const companyLogos = [
    { src: images.Boston, alt: 'Boston Medical Group' },
    { src: images.Clara, alt: 'Clara' },
    { src: images.Findasense, alt: 'Findasense' },
    { src: images.Rappi, alt: 'Rappi' },
    { src: images.Cenit, alt: 'Cenit' },
    { src: images.Fintual, alt: 'Fintual' },
    { src: images.Yara, alt: 'Yara' },
    { src: images.Olarte, alt: 'Olarte' },
    { src: images.NowPorts, alt: 'NowPorts' },
    { src: images.SmartFit, alt: 'SmartFit' },
    { src: images.Fondeadora, alt: 'Fondeadora' },
    { src: images.LaHaus, alt: 'LaHaus' },
];

const Carousel = () => {
    const [offset, setOffset] = useState(0);
    const contentWrapperRef = useRef();
    const firstItemWidth = 200; // Reemplaza esto con el ancho real de tus elementos
    const totalWidth = companyLogos.length * firstItemWidth;

    useEffect(() => {
        const interval = setInterval(() => {
            setOffset((prevOffset) => (prevOffset + 1) % totalWidth);
        }, 25);

        return () => clearInterval(interval);
    }, [totalWidth]);

    useEffect(() => {
        if (offset === 0) {
            // Restablecer inmediatamente la posición al inicio sin animación
            contentWrapperRef.current.style.transition = 'none';
            contentWrapperRef.current.style.transform = `translateX(0px)`;
            // En el siguiente ciclo de renderizado, volver a habilitar la transición
            setTimeout(() => {
                contentWrapperRef.current.style.transition = 'transform 0.5s ease-out';
            });
        }
    }, [offset]);

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                <div
                    ref={contentWrapperRef}
                    className="carousel-content-wrapper"
                    style={{
                        transform: `translateX(-${offset}px)`,
                        transition: offset === 0 ? 'none' : 'transform 0.5s ease-out',
                    }}
                >
                    {companyLogos.map((logo, index) => (
                        <img key={index} src={logo.src} alt={logo.alt} className="carousel-image" />
                    ))}
                    {/* Duplicar visualmente los elementos del carrusel */}
                    {companyLogos.map((logo, index) => (
                        <img key={`duplicate-${index}`} src={logo.src} alt={logo.alt} className="carousel-image" />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;

